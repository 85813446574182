import React, {Component} from 'react';

import './loading-progress.scss';

class LoadingProgress extends Component {
  render(){
    return(
      <div className="progress-bar">
        <div className="indeterminate"/>
      </div>
    )
  }
}

export default LoadingProgress
