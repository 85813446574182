import {combineReducers} from 'redux'

import languageReducer from './languageReducer'
import portfolioReducer from './portfolioReducer'
import leadsFormReducer from './leadsFormReducer'
import trackerReducer from './trackerReducer'

const rootReducer = combineReducers({
  languageReducer,
  portfolioReducer,
  leadsFormReducer,
  trackerReducer
})

export default rootReducer
