import { lowerdash } from "../../helpers/slug"

const initialState = {
  statistics: null, // {}
  articles: null, // []
  event: null, // -1
  loading: null // false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case 'COMPLIANCE_LOAD':
      return {
        ...state,
        loading: action.payload
      }
    case 'COMPLIANCE_WEBSITE_DATA':
      return {
        ...state,
        statistics: action.payload.statistics,
        articles: action.payload.articles
      }
    case 'SPECIFIC_EVENT':
      const { language, eventName } = action.payload
      for(let i = 0; i < (state.articles || []).length; i++) {
        if(state.articles && state.articles[i] && state.articles[i][language] && lowerdash(state.articles[i][language].header) === eventName) {
          return {
            ...state,
            event: i
          }
        }
      }
      return {
        ...state,
        event: -1
      }
    case 'RESET_EVENT':
      return {
        ...state,
        event: null
      }
    default:
      return state
  }
}