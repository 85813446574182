import React from "react";
import ReactDOM from "react-dom";

import { datadogRum } from '@datadog/browser-rum';
import { withLDProvider } from "launchdarkly-react-client-sdk";
import Leanplum from "leanplum-sdk";

import App from "./views/App";

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APP_ID,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  sampleRate: 100,
  trackInteractions: true,
});

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Leanplum.setAppIdForProductionMode(
    `${process.env.REACT_APP_LEANPLUM_APP_ID}`,
    `${process.env.REACT_APP_LEANPLUM_CLIENT_KEY}`
  );
} else {
  Leanplum.setAppIdForDevelopmentMode(
    `${process.env.REACT_APP_LEANPLUM_APP_ID}`,
    `${process.env.REACT_APP_LEANPLUM_CLIENT_KEY}`
  );
}

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
  user: {
    key: process.env.REACT_APP_LD_USER_KEY
  },
  flags: {
    'taralite-com_remove-button-and-link-to-gform_06102022': true,
    'taralite-com_fraud-attempt-banner_20221019': true,
  }
})(App)

ReactDOM.render(<LDProvider />, document.getElementById("root"));
