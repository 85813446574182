import React, { Fragment, useEffect } from "react";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import "./App.scss";
import store from "../redux";
import LoadingProgress from "components/loading-progress/loading-progress";
import { useFlags } from "launchdarkly-react-client-sdk";

const Redirect = Loadable({
  loader: () => import("./redirect/redirect"),
  loading: LoadingProgress,
});

const OVOModalUsahaTNC = Loadable({
  loader: () => import("./product/ovo-modalusaha-tnc"),
  loading: LoadingProgress,
});

const OVOModalUsahaRegistrationForm = Loadable({
  loader: () => import("./product/registration-form"),
  loading: LoadingProgress,
});

const NotFound = Loadable({
  loader: () => import("./not-found/not-found"),
  loading: LoadingProgress,
});

const OJKReport = Loadable({
  loader: () => import("./report/ojk"),
  loading: LoadingProgress,
});

const Main = Loadable({
  loader: () => import("./v2/main/main"),
  loading: LoadingProgress,
});

const OMUBorrower = Loadable({
  loader: () => import("./v2/omu-landing/borrower/omu-borrower"),
  loading: LoadingProgress,
});

const UnderlyingLanding = Loadable({
  loader: () => import("./v2/omu-landing/underlying/underlying"),
  loading: LoadingProgress,
});

const LeadsForm = Loadable({
  loader: () => import("./v2/leads-form/leads-form"),
  loading: LoadingProgress,
});

const AboutUsV2 = Loadable({
  loader: () => import("./v2/about-us/about-us"),
  loading: LoadingProgress,
});

const PayLaterTNC = Loadable({
  loader: () => import("./tnc/opl"),
  loading: LoadingProgress,
});

const PayLaterAutoDebitTNC = Loadable({
  loader: () => import("./tnc/opl/auto-debit"),
  loading: LoadingProgress,
});

const PayLaterLoanAgreement = Loadable({
  loader: () => import("./tnc/opl/loan-agreement"),
  loading: LoadingProgress,
});

const Health = Loadable({
  loader: () => import("./health"),
  loading: LoadingProgress,
});

const PrivacyNotice = Loadable({
  loader: () => import("./v2/privacy-notice"),
  loading: LoadingProgress,
});

const GrabModalMantul = Loadable({
  loader: () => import("./v2/grab-modal-mantul"),
  loading: LoadingProgress,
});

const Paylater = Loadable({
  loader: () => import("./v2/paylater"),
  loading: LoadingProgress,
});

const App = () => {
  useEffect(() => {
    dom.watch();
    library.add(faAngleDown, faAngleRight);

    document.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    document.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );
  }, []);

  const { taraliteComRemoveButtonAndLinkToGform06102022: removeButtonFlag } =
    useFlags();

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/" component={Main} />
            {!removeButtonFlag && (
              <Route
                exact
                path="/product/ovo-modalusaha/form"
                component={OVOModalUsahaRegistrationForm}
              />
            )}
            <Route
              exact
              path="/product/ovo-modalusaha/terms-and-conditions"
              component={OVOModalUsahaTNC}
            />
            <Route exact path="/about" component={AboutUsV2} />
            <Route exact path="/redirect/:url" component={Redirect} />
            <Route exact path="/report" component={OJKReport} />
            <Route exact path="/omu/landing/borrower" component={OMUBorrower} />
            <Route
              exact
              path="/omu/landing/underlying"
              component={UnderlyingLanding}
            />
            <Route exact path="/omu/leads-form" component={LeadsForm} />
            <Route
              exact
              path="/ovo-paylater/terms-conditions"
              component={PayLaterTNC}
            />
            <Route
              exact
              path="/ovo-paylater/terms-conditions/auto-debit"
              component={PayLaterAutoDebitTNC}
            />
            <Route
              exact
              path="/ovo-paylater/loan-agreement"
              component={PayLaterLoanAgreement}
            />
            <Route exact path="/health" component={Health} />
            <Route exact path="/privacy-notice" component={PrivacyNotice} />
            <Route exact path="/GrabModalMantul" component={GrabModalMantul} />
            <Route exact path="/paylater" component={Paylater} />
            <Route component={NotFound} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
