import * as about from "data/languages/lang_about.json";
import * as footer from "data/languages/lang_footer.json";
import * as navbar from "data/languages/lang_navbar.json";
import * as newhome from "data/languages/lang_new_home.json";
import * as ucLanding from "data/languages/lang_omu_landing_uc.json";
import * as faq from "data/languages/lang_faq.json";
import * as leadsForm from "data/languages/lang_omu_leads_form.json";
import * as borrowerLanding from "data/languages/lang_omu_landing_borrower.json";

const initialState = {
  selected: "id",
  languageData: {
    id: {
      navbar: navbar.id,
      about: about.id,
      footer: footer.id,
      newhome: newhome.id,
      ucLanding: ucLanding.id,
      faq: faq.id,
      leadsForm: leadsForm.id,
      borrowerLanding: borrowerLanding.id,
    },
    en: {
      navbar: navbar.en,
      about: about.en,
      footer: footer.en,
      newhome: newhome.en,
      ucLanding: ucLanding.en,
      faq: faq.en,
      leadsForm: leadsForm.en,
      borrowerLanding: borrowerLanding.en,
    },
  },
  prevLanguage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_LANGUAGE":
      return { ...state, selected: action.payload };
    case "SET_PREV_LANGUAGE":
      return { ...state, prevLanguage: action.payload };
    default:
      return state;
  }
};

export default reducer;
