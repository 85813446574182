const initialState = {
  loading: false,
  success: null,
  error: null,
  message: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBMIT_LEADS_FORM':
      return {
        ...state,
        loading: true
      }
    case 'SUBMIT_LEADS_FORM_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data,
        success: true,
        error: false,
        code: action.data.response_code,
        message: action.data.response_message
      }
    case 'SUBMIT_LEADS_FORM_FAILED':
      return {
        ...state,
        loading: false,
        data: action.error,
        success: false,
        error: true,
        httpStatus: action.error?.response?.status,
        code: action.error?.response?.data?.response_code,
        message: action.error?.response?.data?.response_message
      }
    case 'RESET_LEADS_FORM':
      return {
        ...initialState,
      }
    default:
      return state;
  }
}

export default reducer;
